.layout-sidebar {

    .bg-toggler {
        position: fixed;
        top: 0;
        left: 200px;
        right: 0;
        bottom: 0;
        background: transparent;
        z-index: -1000;
        transition: all .2s;

        &.show {
            background: rgba(0,0,0,.5);
            z-index: 1000;
        }

    }

    .dashboard-content, #dashboard-footer {

        .container-fluid {
            // max-width: 95%;
            // width: 1000px;
        }

    }

    // @media(min-width: 1000px) {
    //     @media(max-width: 1280px) {
    //         .dashboard-content, #dashboard-footer {
    //             margin-left: 200px;
    //         }

    //         #desktop-navbar-top {
    //             margin-left: 200px;
    //             width: calc(100% - 200px);
    //         }
    //     }
    // }

    .content-wrapper {
        margin-left: 56px;
    }

    @media(max-width: 1000px) {
        .content-wrapper {
            margin-left: 0 !important;
;
        }
    }

    .navbar.navbar-top {
        left: 200px;
    }

    @media(min-width: 768px) {
        .navbar-vertical .navbar-collapse:before {
            content: '';
            display: block;
            margin: 0;
        }
    }

    .sidenav .sidenav-toggler {
        padding: 1.5rem;
    }

    .sidenav.navbar-vertical.navbar {

        border-right: none !important;

        &:hover {
            .navbar-brand, .navbar-heading, .divider, ul ul {
                display: block;
            }

        }

        .active .nav-link {
            color: darken($body-color, 2%) !important;
            // padding: 0.2rem 1.5rem !important;

        }

        .nav-link:hover {
            color: darken($body-color, 2%);
        }

        .nav-link.active {
            color: darken($body-color, 2%) !important;
            margin-left: 0;
            padding-left: $navbar-vertical-padding-x;
            margin-right: 0;
            background: $gray-200;;
            border-radius: 0;
        }

        .collapse {

            .collapse {

                .nav-link.active {
                    color: darken($body-color, 2%) !important;
                    margin-left: 0;
                    padding-left: $navbar-vertical-padding-x * 2.3;
                    margin-right: 0;
                    background: $gray-400;;
                    border-radius: 0;
                }

            }

        }

        ul ul {
            display: none;
        }

        .nav-item {
            margin-top: 0;
        }

        .navbar-brand {
            padding-right: 0;
            padding-top: 1rem !important;
            padding-bottom: 1rem;

            img {
                max-width: 110%;
            }
        }

        .sidenav-header {
            border-bottom: solid 1px $navbar-vertical-border-color;
            height: 63px;
        }

        .divider {
            border-top: solid 1px $navbar-vertical-border-color;
            border-bottom: solid 1px $navbar-vertical-border-color;
            padding-left: $navbar-vertical-padding-x;
            padding-right: $navbar-vertical-padding-x;
            padding-top: $navbar-vertical-padding-x * .5;
            padding-bottom: $navbar-vertical-padding-x * .5;
            display: none;
        }

    }

    .main-content {

        .container-fluid {
            padding-left: $spacer* 2 !important;
            padding-right: $spacer* 2 !important;
        }

        
        .header {
            background: white;
            margin-left: -$spacer* 2;
            padding-left: $spacer* 2;
            margin-right: -$spacer* 2;
            padding-right: $spacer* 2;
            margin-bottom: 1rem;
            border-bottom: solid 1px $gray-200;
            // -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
            // box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        }

    }

}

.g-sidenav-pinned {
    
    @media(min-width: 1000px) {
        .content-wrapper {
            margin-left: 200px !important;
        }

        .sidenav:hover {
            max-width: 200px;
        }

        .sidenav {
            transform: translateX(0px) !important;
        }
    }

    .navbar-heading, .divider, ul ul {
        display: block !important;
    }

    ul ul {
        display: block !important;
    }

}

@media(min-width: 1000px) {

    

    .sidenav {
        transform: translateX(0px) !important;

        &:hover {
            max-width: 200px;
        }

        .divider {
            display: none;
        }

        // ul ul {
        //      display: none !important;
        // }
    }
}
