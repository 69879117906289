.archk-internal-sidebar {

    
    @media(min-width: 601px) {
        .col-auto {

            padding-right: 0;
        }
    
        .col {
            padding-left: 0;
        }
    
    }

    @media(max-width: 600px) {
        .col-auto, .col {
            display: block;
            width: 100% !important;
        }
    }

    ul {

        padding-left: 0;
        margin-left: 0;
        margin-bottom: 0;
        padding-top: $spacer;
        padding-bottom: $spacer;


        li {
            list-style: none;
            cursor: pointer;

            padding: 2px 14px;
            padding-left: 0;
            margin-left: $spacer * 1.4;
            margin-right: $spacer;

            border: solid 2px white;
            transition: .3s all;
            font-size: $font-size-sm;

            &.active {
                border: solid 2px $green !important;
                color: $green;
                padding-left: 14px;

            }

            &:hover {
                border: solid 2px $gray-300;
                padding-left: 14px;

            }
        }

    }

}