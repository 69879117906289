.form-control:disabled {
    background-color: $gray-300 !important;
}

select {
    -webkit-appearance: none !important;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: $input-bg;
    opacity: 0.7;
}
.form-control:focus {
    color: #8898aa;
    background-color: #fff;
    border-color: #06405C;
    outline: 0;
    // box-shadow: $box-shadow-sm;
}
.rdtPicker .rdtTimeToggle:before {

    content: "Time Select";
}

.form-group {

    .form-control-label {
        position: relative;
        left: 0;
        transition: all .3s;
    }

    &:focus-within {
        .form-control-label {
            color: $orange; 
            position: relative;
            // left: 6px;
        }
    }
}
// .form-group {

//     // &:hover {

//     //     .form-control-label {
//     //         color: $orange;
//     //     }

//     // }

//     .form-control:focus + .form-control-label {
//         background-color:purple;
//         color: red;
//     }

// }

.form-control {
    // background: #f8f8ff;
    background: $gray-100;
    border-radius: $border-radius;
    padding-left: 16px;
    padding-right: 16px;
    transition: all .3s;

    &:focus {
        border: solid 1px $orange;
        color: $orange;
        font-weight: bold;
        border-radius: 0;

        &::placeholder {
            color: lighten($orange, 20%);
        }
    }
}

.form-group {
    margin-bottom: $spacer;
}

.custom-checkbox .custom-control-label {

    &::before {
        top: -0px;
    }

    &::after {
        top: -0px;
    }

}

input[type='checkbox'], input[type='radio'] { filter: grayscale(.8) }

// input[type="checkbox"] { /* change "blue" browser chrome to yellow */
//     filter: invert(100%) hue-rotate(28deg) brightness(1);
//   }

.react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;

        input {
            width: 100%;
            background: $body-bg;
            padding: $input-btn-padding-y $input-btn-padding-x;
            border-radius: $border-radius;
            color: $input-color;

            border: solid 1px $input-border-color;
            border-width: $input-border-width;
            box-shadow: $input-box-shadow;
            &:active {
                background: $input-bg;
            }
        }

    }

}

.date-picker-wrapper {

    &.invalid {
        input {
            border: solid 1px $red;
        }
    }

    &.valid {
        input {
            border: solid 1px $green;
        }
    }
}

label {
    margin-bottom: $spacer / 2.5;
}

@media(max-width: 576px) {

    select, input, textarea {
        font-size: 16px !important;
    }

    .form-control  {

        padding: .2rem .7rem !important;
        height: calc(2rem + 2px) !important;

        select, input, textarea {
            font-size: 16px !important;
        }
    }

}

.statements-date-picker input {
    text-align: center;
    max-width: 150px;
    border-radius: 0 !important;
    cursor: pointer;
}